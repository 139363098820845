import story_default from "./../../../assets/images/defaults/me_energy_default.png";
import * as moment from "moment";
class DefaultClass {
  static getTestimonialsDefaultPhoto() {
    return story_default;
  }
  static getMoment(){
    return moment; 
  }
}

export default DefaultClass;
