module.exports = {
  PARAGRAPH :"paragraph", 
  HEADER : "header", 
  LIST : "list", 
  ORDERED : "ordered", 
  UNORDERED : "unodered",
  IMAGE : "simpleImage",
  PARA_START : "<p style='margin:0px; margin-bottom:0px;text-align:justify;line-height:24px;'> ", 
  PARA_END : " </p> ", 
  H1 : "<h1 style='margin:0px; margin-bottom:7px;font-size:2em !important;'> ", 
  H2:"<h2 style='margin:0px; margin-bottom:7px;font-size:1.5em !important;'> ",
  H3:"<h3 style='margin:0px; margin-bottom:7px;font-size:1.17em !important;'> ",
  H4:"<h4 style='margin:0px; margin-bottom:7px;font-size:1em !important;'> ",
  H5:"<h5 style='margin:0px; margin-bottom:7px;font-size:.83em !important;'> ",
  H6:"<h6 style='margin:0px; margin-bottom:7px;font-size:.67em !important;'> ",
  H1_END : "</h1> ", 
  H2_END : "</h2> ", 
  H3_END : "</h3> ", 
  H4_END : "</h4> ", 
  H5_END : "</h5> ", 
  H6_END : "</h6> ", 
  IMG_START : "<img style='width:100%; height:80%;object-fit:contain;margin-bottom:5px;margin-top:8px; ' ",
  IMG_START_END :">",
  // IMG_SRC_START = "src='", 
  // IMG_SRC_END = "'", 
  IMG_END : "</img>", 
  LIST_START : "<li style='margin-bottom:5px'>", 
  LIST_END : "</li>",
  UL_START:"<ul style='list-style:disk !important;margin-top:3px;margin-bottom:3px;padding-left:40px !important;'>", 
  UL_END:"</ul>", 
  OL_START:"<ol style='list-style-type:decimal !important;margin-top:3px;margin-bottom:3px;padding-left:40px !important;'>", 
  OL_END:"</ol>",
  SPACE:"[SPACE]",
  BREAK:"<p style='opacity:0 !important;margin:3px !important'> ....  </p>",
  SMALL_START:"<p style='font-size:.85rem;margin:0px;margin-bottom:10px;text-align:center;'>", 
  SMALL_END:"</p>", 
  DOT:".",
  NEW_EDITOR_IDENTITY:"@_ME_NEW_CUSTOM_EDITOR_@",




}